import { useState, useEffect } from "react";
import "./QuizC.css";
import CurrentQuiz from "./CurrentQuizCD.js";
import PastQuiz from "./PastQuizCD.js";
import {motion, AnimatePresence} from "framer-motion";
import Createquiz from "./CreateQuizD.js";

const Quiz = ({ currentQuizzes, pastQuizzes, answerList, setCreate, openCurrQuiz }) => {
  const [oCreate, sCreate] = useState(false);
  


  let alKeys = Object.keys(answerList);

  const [QuizListCurrent, setQuizListCurrent] = useState();

  const [QuizListPast, setQuizListPast] = useState();

  useEffect(() => {
    const updatedQuizList = currentQuizzes.map((item, index) => (
        <CurrentQuiz
          quiz={item}
          idd={item.id}
          quizName={item.name}
          sub={item.subtitle}
          timeLeft={`12/25 Submitted`}
          openCurrQuiz={openCurrQuiz}
        />
      ));

    const updatedQuizListPast = pastQuizzes.map((item, index) => (
        <PastQuiz
          whileTap={{ scale: 0.9 }}
          quiz={item}
          answers={answerList[item.id]}
        />
      ));
    setQuizListPast(updatedQuizListPast);
    setQuizListCurrent(updatedQuizList);
  }, [pastQuizzes, currentQuizzes]);



  return (
    <div className="QuizD">
      {/* header */}

      <h className="quizzesHead">Quizzes</h>

      <motion.div whileTap={{ scale: 0.9 }} onClick={() => setCreate(true)} className="createCapD">
      
        <div className="creatInnerflexD">
        <svg
          fill="black"
          width="2.7vw"
          height=""
          data-name="Layer 1"
          viewBox="0 0 300 300"
        >
          <path d="m194.71,126.66c0,3.77-3.06,6.84-6.83,6.84h-81.69c-3.76,0-6.83-3.07-6.83-6.84s3.07-6.83,6.83-6.83h81.69c3.77,0,6.83,3.06,6.83,6.83Z" />
          <path d="m270.62,120.57l-10.28-6.77c-2.05-1.33-4.43-2.03-6.86-2.03h-.62c-4.28,0-8.23,2.13-10.58,5.71l-60.12,92.1c-.45.74-.86,1.52-1.25,2.38l-9.56,25.2c-2.14,5.58-.16,11.77,4.81,15.08,2.1,1.41,4.54,2.15,7.05,2.15h.13c3.16,0,6.18-1.17,8.53-3.3l19.83-18.55c.77-.72,1.46-1.53,2.03-2.41l60.08-92.05c3.87-5.68,2.45-13.53-3.19-17.51Zm-84.92,117.51l8.02-21.11,59.49-91.14,8.71,5.67-59.6,91.13-16.62,15.45Z" />
          <path d="m159.71,220.02c0,3.76-3.07,6.83-6.84,6.83h-46.68c-3.76,0-6.83-3.07-6.83-6.83s3.07-6.84,6.83-6.84h46.68c3.77,0,6.84,3.07,6.84,6.84Z" />
          <path d="m178.29,173.34c0,3.77-2.53,6.83-5.65,6.83h-67.62c-3.11,0-5.65-3.06-5.65-6.83s2.54-6.84,5.65-6.84h67.62c3.12,0,5.65,3.07,5.65,6.84Z" />
          <path d="m194.71,79.98c0,3.77-3.06,6.84-6.83,6.84h-81.69c-3.76,0-6.83-3.07-6.83-6.84s3.07-6.83,6.83-6.83h81.69c3.77,0,6.83,3.07,6.83,6.83Z" />
          <path d="m222.39,128.27V56.64c0-9.1-7.4-16.5-16.5-16.5H54.19c-9.1,0-16.51,7.4-16.51,16.5v186.72c.18,8.89,7.58,16.15,16.5,16.15h151.35c1.05.12,2.12.12,3.17.01,1.26-.22,2.53-.65,3.71-1.29,5.83-2.69,9.54-8.52,9.52-14.87v-5.44l13.67-20.37v26.15c.03,9.47-4.48,18.5-12.08,24.15-1.94,1.5-4.11,2.73-6.42,3.63-2.77,1.1-5.69,1.79-8.68,2.05H54.19c-16.64,0-30.18-13.54-30.18-30.17V56.64c0-16.63,13.54-30.17,30.18-30.17h151.7c16.64,0,30.17,13.54,30.17,30.17v50.71l-13.67,20.92Z" />
          <path d="m72.91,139.9h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m72.91,186.57h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m72.91,233.25h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m67.45,94.87c-1.52,0-2.96-.73-3.87-1.96l-12.31-16.84c-1.56-2.14-1.1-5.14,1.04-6.7,2.14-1.56,5.14-1.1,6.7,1.04l7.99,10.93,16.06-27.52c1.33-2.29,4.27-3.06,6.56-1.73,2.29,1.33,3.06,4.27,1.72,6.56l-19.75,33.84c-.81,1.4-2.28,2.29-3.89,2.37-.08,0-.17,0-.25,0Z" />
        </svg>
          <div className="createTextD">Create a Quiz</div>
          
        </div>
        <svg width="1.2vw" height="1.2vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="#5259FB"
              />
            </svg>
      </motion.div>


      <div className="currentQuizD">Current Quizzes</div>
      <div className="cQuizOver">{QuizListCurrent}</div>
      <div className="pastQuizD">Past Quizzes</div>
      {QuizListPast}
      

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oCreate && (
          <Createquiz
            CS={sCreate}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Quiz;
