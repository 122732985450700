import "./UploadFiles.css";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import { Pagination, EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { Navigation } from "swiper/modules";

import * as pdfjsLib from 'pdfjs-dist/webpack';

const UploadFiles = ({ close, setUpImgList, upImgList }) => {
  const upload = () => {};
  const addPic = () => {};
  const [swipeKey, setSwipeKey] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.activeIndex);
  };

  const deleteCurrentSlide = () => {
    if (currentSlideIndex > 0) {
      setUpImgList((prevImgList) =>
        prevImgList.filter((_, index) => index + 1 !== currentSlideIndex)
      );
    }
  };

  const handlePdf = async (file) => {
    const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    const pages = [];

    for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const viewport = page.getViewport({ scale: 1.5 });

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
        canvasContext: context,
        viewport: viewport,
    };

    await page.render(renderContext).promise;
    const imageUrl = canvas.toDataURL('image/png');
    pages.push(imageUrl);
    }

    setUpImgList([...upImgList, ...pages]);
};

  const handleFileChange = async (e) => {

    const files = e.target.files;
    const file = e.target.files[0];
    if (file) {
      const validExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (validExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setUpImgList([...upImgList, reader.result]);
        };
      } else if (fileExtension == "pdf") {
        console.log("pdf");


            if (file.type === 'application/pdf') {
              await handlePdf(file);
            } else if (file.type.startsWith('image/')) {
              const imageUrl = URL.createObjectURL(file);
              setUpImgList([...upImgList, imageUrl]);
            }
        
      } else {
        console.log("invalid");
      }
    }
  };

    


  useEffect(() => {
    setSwipeKey(swipeKey + 1);
  }, [upImgList]);

  const fileInputRef = useRef();

  function handleDivClick() {
    fileInputRef.current.click();
  }

  const goToSlide = (index) => {
    if (swiperRef.current) {
        console.log("hello");
      swiperRef.current.swiper.slideTo(index + 1);
    }
  };

  return (
    <div>
      <motion.div className="fullProfDD" onClick={close}></motion.div>

      <div className="UPbox">
        <div className="topUpBar">
          <div className="topUPflex">
            <svg
              width="1.5vw"
              height="1.5vw"
              viewBox="0 0 11 19"
              onClick={close}
              fill="none"
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>Upload Files</div>
          </div>
          <svg
            width="2.5vh"
            height=""
            viewBox="0 0 16 16"
            fill="none"
            onClick={deleteCurrentSlide}
          >
            <path
              d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
              fill="red"
            />
          </svg>
        </div>
        <Swiper
          onSlideChange={handleSlideChange}
          key={swipeKey}
          ref={swiperRef}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={false}
          slidesPerView={"auto"}
          spaceBetween={"1vw"}
          coverflowEffect={{
            rotate: 0,
            stretch: -10,
            depth: 70,
            modifier: 2.5,
            slideShadows: false,
          }}
          pagination={{ el: "", clickable: true }}
          modules={[EffectCoverflow, Navigation, Pagination]}
          style={{
            width: "90%",
            position: "relative",
            top: "2vw",
            height: "14vw"
          }}
        >
          <SwiperSlide
            key={swipeKey}
            style={{
              paddingBottom: "10vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "13vw",
              width: "8vw",
              borderRadius: "1vw",
            }}
          >
            <div className="addBox" onClick={handleDivClick}>
              <input
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
                className="imgSelect"
              ></input>
              <motion.svg
                whileTap={{ scale: 0.9 }}
                width="2.5vw"
                height="2.7vw"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="addI"
              >
                <circle cx="26" cy="26" r="26" fill="#0072E2" />
                <path
                  d="M17 26H35"
                  stroke="white"
                  stroke-width="6"
                  stroke-linecap="round"
                />
                <path
                  d="M26 35V17"
                  stroke="white"
                  stroke-width="6"
                  stroke-linecap="round"
                />
              </motion.svg>
            </div>
          </SwiperSlide>
          {upImgList.map((img, index) => (
            <SwiperSlide
              key={index}
              style={{
                paddingBottom: "10vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "13vw",
                width: "8vw",
                borderRadius: "1vw",
              }}
              onClick={() => goToSlide(index)}
            >
              <div className="addBox">
                <img src={img} className="playPic2D"></img>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default UploadFiles;
