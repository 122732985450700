import { useState, useEffect } from "react";
import "./HomeC.css";
import { motion, AnimatePresence } from "framer-motion";
import plays from "../JSON/Plays.json";
import PlayNot from "./PlayNot.js";
import Leaderboard from "./LeaderboardD.js";
import Roster from "./RosterD.js";
import QuizNot from  "./QuizNot.js";


const Home = ({ firstName, OProf,pic, name, groups, answerList, currentQuizzes, currQuizClicked, QuizzesInfo, starred, setCurrentPlays, setOpenS }) => {
  const [toResult, setToResult] = useState(false);
  const [openLB, setOpenLB] = useState(false);
  const [openR, setOpenR] = useState(false);
  const [startOpen, setStartOpen] = useState(false);
  const [activeQuiz, setActiveQuiz] = useState({});
  const [activeID, setActiveID] = useState(0);






  function Ostart(quizz, id) {
    setActiveID(id);
    setActiveQuiz(quizz);
    setStartOpen(true);
  }
  


  //establish list of all plays
  const [PlaysInfo, setPlaysInfo] = useState([]);
  for (const [playId, playData] of Object.entries(plays)) {
    if (groups.includes(playData.group)) {
      PlaysInfo.push(playData);
    }
  }

  let alKeys = Object.keys(answerList);


  const [QuizList, setQuizList] = useState(currentQuizzes.map((item, index) => {
return <QuizNot
      quiz={item}
      idd={item.id}
      quizName={item.name}
      sub={item.subtitle}
      time={`12/25 Submitted`}
      currQuizClicked={currQuizClicked}
      Ostart={Ostart}
    />
  }
    
  ));

  useEffect(() => {
    console.log("hi");

    const updatedQuizList = currentQuizzes.map((item, index) => (
      <QuizNot
        quiz={item}
        idd={item.id}
        quizName={item.name}
        sub={item.subtitle}
        time={`12/25 Submitted`}
        currQuizClicked={currQuizClicked}
        Ostart={Ostart}
      />
    ));


    setQuizList(updatedQuizList)
  }, [currentQuizzes]);



  return (
    <div
    className="HomeD">

      {/*Profile Pic*/}
      <motion.div className="profPicD" onClick={OProf} whileTap={{ scale: 0.9 }}>
        <img src={pic} className="pic"  />
      </motion.div>
      {/* header */}
      <div className="greetingD">Welcome Back, {name}.</div>

      <div className="notQuizCD">
        <div className="notQuizHeadCD">Current Quizzes</div>
        <div className="notQuizCapCD">
        {QuizList}
        </div>
      </div>


      {/* Notifications */}
      <div className="notificationsCD">
        <div className="notHeaderCD">Notifications</div>
        <div className="notCapCD">
          {/* Map each play to a component */}
          {PlaysInfo.map((item, index) => {
            return (
              <PlayNot play={item} duration={`${index * 2 - 1 + 4} hours`} />
            );
          })}
        </div>
      </div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openLB && (
          <Leaderboard CS={setOpenLB}/>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openR && (
          <Roster close={setOpenR}/>
        )}
      </AnimatePresence>
      
    </div>
  );
};

export default Home;
