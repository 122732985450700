import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./AttemptC.css";
import Question from "./QuestionC";
import Swiper from "swiper";
import "/node_modules/swiper/swiper.min.css";

const Attempt = ({handleClose, quiz, correctAnswers }) => {


  const [PicURL, setPicURL] = useState(require(`./Plays/SL.jpg`));
  const [nextText, setNextText] = useState("Next");
  const [backText, setBackText] = useState("Exit");
  const swiperRef = useRef(null);
  const [qNum, setQNUM] = useState(1);
  const [isPic, setIsPic] = useState(false);


  const goNext = () => {
    if (qNum < quiz.questions.length) {
      setQNUM(qNum + 1);
      if (qNum === quiz.questions.length - 1) {
        setNextText("Exit");
      }
      else {
        setNextText("Next");
      }
      if (qNum > 0) {
        setBackText("Back");
      }
      else {
        setBackText("Exit");
      }

      setPicURL(require(`${quiz.pics[qNum]}`));
    } else {
      handleClose();
    }
    setIsPic(false);
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goBack = () => {
    if (qNum > 1) {
      setQNUM(qNum - 1);
      if (qNum === quiz.questions.length - 1) {
        setNextText("Exit");
      }
      else {
        setNextText("Next");
      }
      if (qNum > 0) {
        setBackText("Back");
      }
      else {
        setBackText("Exit");
      }

      setPicURL(require(`${quiz.pics[qNum]}`));
    } else {
      handleClose();
    }
    setIsPic(false);
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };



  function setPic() {
    setIsPic(!isPic);
  }

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        direction: "horizontal",
        loop: false,
        slidesPerView: 1, 
        spaceBetween: "30vw",
        allowTouchMove: false,
      });
    }
  }, []);


  const dropIn = {
    hidden: {
      x: "100vw",
      opacity: 1,
    },
    visible: {
      x: "0vw",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 30,
        stiffness: 300,
      },
    },
    exit: {
      x: "100vw",
      opacity: 1,
    },
  };


  return (
    <motion.div
      className="fullAttempt"
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div className="attempt-Cap">
        
        <div className="attemptHead">{quiz.Title}</div>
        <div className="attemptSub">{`Question ${qNum}/${quiz.questions.length}`}</div>

        

        <div className="swiper-container" ref={swiperRef}>
          <div className="swiper-wrapper">
            {correctAnswers.map((question, index) => (
              <div key={index} className="swiper-slide" id="questionSwiperItem">
                <Question
                  correctIndex={correctAnswers[index]}
                  i={index}
                  aList={quiz.possible_answers[index]}
                  tit={quiz.questions[index]}
                  p={quiz.pics[index]}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="esFlex">
        <motion.div className="eOb" whileTap={{ scale: 0.9 }} onClick={goBack}>
          {backText}
        </motion.div>

        <motion.div className="sOn2" whileTap={{ scale: 0.9 }} onClick={goNext}>
          {nextText}
        </motion.div>
        </div>
        

        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {isPic && (
            <div className="playPicCapVar" onClick={setPic}>
              <img src={PicURL} className="playPicVar"></img>
            </div>
          )}
        </AnimatePresence>

        
      </motion.div>
    </motion.div>
  );
};

export default Attempt;
