import { useState } from "react";
import { motion, AnimatePresence, transform } from "framer-motion";
import "./ViewQuiz.css";
import Attempt from "./AttemptC";
import Modal from "./ModalD";

const StartQuiz = ({ idd, handleClose, quiz, correctAnswers }) => {
  const quizPTS = quiz.questions.length * 10;
  const CPname = quiz.coachesPic;
  const QPname = quiz.coverPlay;
  const CP = require(`${CPname}`);
  const QP = require(`${QPname}`);

  const [attemptOpen, setAttemptOpen] = useState(false);
  const [del, setDel] = useState(false);

  function OCattempt(){
    setAttemptOpen(!attemptOpen);
  }

  function d () {

    setDel(false);
  }

  const dropIn = {
    hidden: {
      x: "100vw",
      opacity: 1,
    },
    visible: {
      x: "0vw",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 31,
        stiffness: 300,
      },
    },
    exit: {
      x: "100vw",
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="fullStart"
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div className="start-Cap">
        <div onClick={() => handleClose(false)} className="back-container">
          <svg
            width="2vw"
            height="5.1vw"
            className="triBack"
            viewBox="0 0 99 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z"
              fill="black"
            />
          </svg>
          <div className="backTxt">Back</div>
        </div>

        <div className="QuizTitle">{quiz.Title}</div>
        <div className="QuizSub">{quiz.Subtitle}</div>
        <div className="QuizGroup">{quiz.Group}</div>

        <div className="qAd">
        <div className="questions2">{`${quiz.questions.length} Questions`}</div>
        <svg
              width="4.5vw"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => setDel(true)}
            >
              <path
                d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
                fill="red"
              />
            </svg>
        </div>
        
        <div className="circleCap">
          <svg
            width="25vw"
            height="25vw"
            viewBox="0 0 239 239"
            fill="none"
            className="blueCircle"
          >
            <circle
              cx="119.5"
              cy="119.5"
              r="110"
              stroke="#0072E2"
              stroke-width="19"
            />
          </svg>
          <div className="quizPTS">{quizPTS}</div>
          <div className="PTS">Pts</div>
        </div>

        <div className="previewCap2">
          <div className="coachPrevPic">
            <img src={CP} className="pic" />
          </div>
          <div className="coachName">{quiz.Coach_name}</div>
          <div className="timeLeftPrev">11 Hours Left</div>
            <img src={QP} className="QP" />
          <motion.button className="startButton" whileTap={{ scale: 0.9 }} onClick={OCattempt}>View Quiz</motion.button>
        </div>
      </motion.div>


      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {attemptOpen && (
          <Attempt handleClose={OCattempt} idd={idd} correctAnswers={correctAnswers} handlePrevPage={handleClose} quiz={quiz} />
        )}
      </AnimatePresence>


      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && (
          <Modal del={d} close={()=> setDel(false)}/>
        )}
      </AnimatePresence>


    </motion.div>
  );
};

export default StartQuiz;
