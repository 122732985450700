import { useState } from "react";
import { motion } from "framer-motion";
import "./Question.css";


const Question = ({  correctIndex, setPic, tit, aList, p}) => {

  const QP = require(`${p}`);


  function textSize(txt) {
    if(txt.length > 130) {
      return "xsText";
    }
    else if(txt.length > 115) {
      return "sText";
    }
    else if(txt.length > 80) {
      return "mText";
    }
    else if(txt.length > 50) {
      return "lText";
    }
    else {
      return "xlText";
    }
  }

  function textSizeAnswer(txt) {
    if(txt.length > 50) {
      return "sTextA";
    }
    else if (txt.length > 40){
      return "mTextA";
    }
    else {
      return "lTextA";
    }
  }

  return (
    <div className="QuestionD">
        <div className="PnQD">
          <div className="inPD">
            <div className="questionTextD" id={textSize(tit)}> {tit}  </div>
          </div>
          <div className="inPD">
            <div className="playPicCapD">
              <img src={QP} className="playPicD" onClick={setPic}></img>
            </div>
          </div>
          
        </div>

        <div className="answers">
          <div className={correctIndex == 0 ? "unAnswer2D" : "answerD"} > <div className="innerAD" id={textSizeAnswer(aList[0])}>{aList[0]}</div></div>
          <div className={correctIndex == 1 ? "unAnswer2D" : "answerD"} > <div className="innerAD" id={textSizeAnswer(aList[1])}>{aList[1]}</div></div>
          <div className={correctIndex == 2 ? "unAnswer2D" : "answerD"} > <div className="innerAD" id={textSizeAnswer(aList[2])}>{aList[2]}</div></div>
          <div className={correctIndex == 3 ? "unAnswer2D" : "answerD"} > <div className="innerAD" id={textSizeAnswer(aList[3])}>{aList[3]}</div></div>
        </div>

        
    </div>
  );
};

export default Question;