import { useState, useEffect, useRef } from "react";
import "./Quiz/ResultC.css";
import Foot from "./Foot/footD.js";
import quizzes from "./JSON/Quiz.json";
import Profs from "./JSON/profilesC.json";
import plays from "./JSON/Plays.json";
import Home from "./Home/HomeC.js";
import { motion, AnimatePresence, color } from "framer-motion";
import Quiz from "./Quiz/QuizCD.js";
import CreateQuizD from "./Quiz/CreateQuizD.js";
import "./SPACD.css";
import "./QuizResultD.css";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import "/node_modules/swiper/swiper.min.css";
import Profile from "./ProfileC.js";
import PlayView from "./Play/PlayView.js";
import Playbook from "./Play/Playbook.js";
import Starred from "./Home/Starred.js";
import RosterD from "./Home/RosterD.js";
import folders from "./JSON/Folders.json";
import Leaderboard from "./Home/LeaderboardD.js";
import ben from "./Profiles/ben.jpeg";
import griffin from "./Profiles/griff.jpeg";
import christian from "./Profiles/prof.webp";
import dPlay from "./Plays/R.jpg";
import FolderLevel from "./Play/folderLevel.js";
import Plays from "./JSON/Plays.json";
import CreatequizD from "./Quiz/CreateQuizD.js";
import NewQ from "./Quiz/NewQD.js";
import PlayViewD from "./PlayViewD.js";
import NewFolder from "./NewFolder.js";
import DeleteFolderD from "./DeleteFolder.js";
import SearchMod from "./SearchMod.js";
import UploadFiles from "./UploadFiles.js";
import Filter from "./pFilter";
import PastQuiz from "./Quiz/PastQuizCD.js";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CurrQuizView from "./CurrQuizView.js";
import SeeByQ from "./SeeByQ.js";
import NewPlayer from "./NewPlayer.js";

const SPAC = ({ name }) => {
  const playerInfo = Profs[name];
  const [whichPage, setPage] = useState(1);
  const nameDB = name.charAt(0).toLowerCase() + name.slice(1);
  const firstName = playerInfo.name.split(" ")[0];
  const Name = playerInfo.name;
  const num = playerInfo.num;
  const picName = playerInfo.pic;
  const groups = playerInfo.groups;
  const [answerList, setAnswerList] = useState({
    1: [0, 2, 1, 1, 1, 2, 2, 0, 0, 1, 0],
  });
  const QuizzesInfo = [];
  const pic = require(`${picName}`);
  const [right, setRight] = useState("-30vw");
  const [openProf, setOpenProf] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openPB, setOpenPB] = useState(false);
  const [moveProf, setMoveProf] = useState(false);
  const [keyn, setKeyn] = useState(0);
  const [currentPlays, setCurrentPlays] = useState(playerInfo.Splays);
  const [favoritePlays, setFavoritePlays] = useState(playerInfo.Splays);
  const [recentPlays, setRecentPlays] = useState(playerInfo.Splays.slice(0, 6));
  const [coachPlays, setCoachPlays] = useState(playerInfo.Splays);
  const [deleted, setDeleted] = useState([]);
  const [isTog, setIsTog] = useState(false);

  const [seeBy, setSeeBy] = useState(false);

  const [upImgList, setUpImgList] = useState([]);

  const [openPV, setOpenPV] = useState(false);
  const [openDF, setOpenDF] = useState(false);
  const [openNF, setOpenNF] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);


  class question {
    constructor(answers, correct, q, time, pic) {
      this.correct = correct;
      this.answers = answers;
      this.q = q;
      this.time = time;
      this.pic = pic;
    }
  }

  class QuizObj {
    constructor(
      name,
      subtitle,
      dueDate,
      group,
      coverPlay,
      coachName,
      pics,
      dateOfCreation,
      questions,
      possibleAnswers,
      id,
      correctAnswers,
      timeList
    ) {
      this.name = name;
      this.subtitle = subtitle;
      this.dueDate = dueDate;
      this.group = group;
      this.coverPlay = coverPlay;
      this.coachName = coachName;
      this.pics = pics;
      this.dateOfCreation = dateOfCreation;
      this.questions = questions;
      this.possibleAnswers = possibleAnswers;
      this.id = id;
      this.correctAnswers = correctAnswers;
      this.timeList = timeList;
    }
  }

  const [currentQuizzes, setCurrentQuizzes] = useState(() => {
    let n = [];
    let alKeys = Object.keys(answerList);

    for (const [id, quizData] of Object.entries(quizzes)) {
      if (quizData.Group === "Running Backs") {
        const quiz = new QuizObj(
          quizData.Title,
          quizData.Subtitle,
          quizData.Due_date,
          quizData.Group,
          quizData.coverPlay,
          quizData.Coach_name,
          quizData.pics,
          quizData.date_of_creation,
          quizData.questions,
          quizData.possible_answers,
          parseInt(id),
          quizData.correct_answers,
          quizData.timeList
        );

        if (!alKeys.includes(quiz.id.toString())) {
          n.push(quiz);
        }
      }
    }
    return n;
  });

  const [pastQuizzes, setPastQuizzes] = useState(() => {
    let n = [];
    let alKeys = Object.keys(answerList);

    for (const [id, quizData] of Object.entries(quizzes)) {
      if (quizData.Group === "Running Backs") {
        const quiz = new QuizObj(
          quizData.Title,
          quizData.Subtitle,
          quizData.Due_date,
          quizData.Group,
          quizData.coverPlay,
          quizData.Coach_name,
          quizData.pics,
          quizData.date_of_creation,
          quizData.questions,
          quizData.possible_answers,
          parseInt(id),
          quizData.correct_answers,
          quizData.timeList
        );

        if (alKeys.includes(quiz.id.toString())) {
          n.push(quiz);
        }
      }
    }
    return n;
  });

  const [create, setCreate] = useState(false);

  const [openUP, setUP] = useState(false);
  const [openSearch, setSearch] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [resultList, setResultList] = useState([]);

  const [comments, setComments] = useState({});

  const [group, setGroup] = useState(groups[0]);
  const [groupTF, setGroupTF] = useState(new Array(groups.length).fill(false));
  let grouptf = groupTF;
  grouptf[0] = true;

  const [isRLS, setIsRLS] = useState(0);

  const isCorrect = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [currID, setCurrID] = useState(0);

  function onRLS(i) {
    setIsRLS(i);
  }

  function SCP(plays) {
    setCurrID(plays);
    if (plays.length > 0) {
      setOpenPV(true);
    }
  }

  const [playsData, setPlaysData] = useState({ ...plays });
  const [newPlayId, setNewPlayId] = useState(17);
  const descRef = useRef(null);
  const titRef = useRef(null);

  const [p, setP] = useState(() => {
    let pp = [];
    coachPlays.map((value, index) => {
      pp.push(playsData[value].parentFolder);
    });
    return pp;
  });

  const [t, setT] = useState(() => {
    let tt = [];
    coachPlays.map((value, index) => {
      tt.push(playsData[value].title);
    });
    return tt;
  });

  const filterResults = (fName) => {
    let newR = [];

    p.forEach((element, index) => {
      if (element === fName.name) {
        newR.push(playsData[coachPlays[index]]);
      }
    });
    setResultList(newR);
  };

  useEffect(() => {
    filterResults(highestLevel);
  }, [p]);

  const [DD, setDD] = useState(null);
  const titleQuiz = useRef(null);
  const subQuiz = useRef(null);

  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Refq = useRef(null);
  const [selectedTime, setSelectedTime] = useState("15 Seconds");
  const [Qpic, setQpic] = useState(null);

  useEffect(() => {}, [Qpic]);

  const [questionL, setQuestionL] = useState([]);

  function addQuestion() {
    let aRefs = [
      Ref1.current.value,
      Ref2.current.value,
      Ref3.current.value,
      Ref4.current.value,
    ];

    let newq = new question(
      aRefs,
      Ref1.current.value,
      Refq.current.value,
      selectedTime,
      Qpic
    );

    setQuestionL((prev) => [...prev, newq]);
  }

  /////////////////////////////////////////////recursive tree
  class node {
    constructor(name, childNodes, parentNode, ii) {
      this.name = name;
      this.childNodes = childNodes;
      this.parentNode = parentNode;
      this.ii = ii;
    }

    setCN(CN) {
      this.childNodes.push(CN);
    }
  }
  let names = [];
  let parents = [];
  for (const [name, par] of Object.entries(folders)) {
    names.push(name);
    parents.push(par);
  }
  function recurseTree(name, parent, ii) {
    if (parents.includes(name)) {
      let childNodes = [];
      let i = 0;
      while (parents.includes(name)) {
        const index = parents.indexOf(name);
        childNodes.push(recurseTree(names[index], name, i));
        names.splice(index, 1);
        parents.splice(index, 1);
        i++;
      }
      return new node(name, childNodes, parent, ii);
    } else {
      return new node(name, [], parent, 0);
    }
  }
  const [tree, setTree] = useState(recurseTree("Running Backs"));

  ////////////////////////////////////////////recursive tree

  const [highestLevel, setHighestLevel] = useState(tree);
  const [HLC, setHLC] = useState(false);
  const [highestLevel2, setHighestLevel2] = useState(tree);
  const [zzz, setZZZ] = useState([false, false, false]);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [nodeList, setNodeList] = useState([tree]);
  const [bof, setBof] = useState(true);


  let NLkey = 0;

  const [folderComponent, setFolderComponent] = useState(
    <FolderLevel
      folderClicked={folderClicked}
      names={highestLevel.childNodes}
    ></FolderLevel>
  );

  function addFolder(namez) {
    const newf = new node(namez, [], highestLevel, 0);

    highestLevel.setCN(newf);
    setHLC(!HLC);
  }

  function DeleteFolder() {
    let newArray = highestLevel2.childNodes.filter(
      (item) => item !== highestLevel
    );
    highestLevel2.childNodes = newArray;
    setHLC(!HLC);
  }

  function folderClicked(level, name, zzz) {
    setBof(true);
    if (level > 1) {
      let nn = nodeList.slice();

      nn.push(name);
      setNodeList(nn);

      setZZZ(zzz);
      setHighestLevel2(highestLevel);
      setHighestLevel(name);
    } else {
      let nn = nodeList.slice();
      if (NLkey > 1) {
        nn.splice(-1, 2);
      }

      nn.push(name);
      setNodeList(nn);

      setZZZ(zzz);
      setHighestLevel(name);
    }
  }

  function back() {
    if (nodeList.length > 2) {
      setBof(false);
      let nn = nodeList.slice();
      nn.pop();
      setNodeList(nn);

      setHighestLevel(highestLevel2);
      setHighestLevel2(nn[nn.length - 2]);
    } else {
      let nn = nodeList.slice();
      nn.pop();
      setNodeList(nn);
      setHighestLevel(highestLevel2);
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      if (highestLevel.name != "Running Backs") {
        setFolderComponent(() => {
          let newFC = [];
          let newZZZ = new Array(highestLevel2.childNodes.length).fill(false);
          newZZZ[highestLevel.ii] = true;
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel2.childNodes}
              keyn={keyn}
              zzz={bof ? zzz : newZZZ}
              level={1}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel.childNodes}
              keyn={keyn}
              level={2}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          return newFC;
        });
      } else {
        setFolderComponent(() => {
          let newFC = [];
          let newZZZ = new Array(highestLevel2.childNodes.length).fill(false);
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel2.childNodes}
              keyn={keyn}
              zzz={newZZZ}
              level={1}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          return newFC;
        });
      }
    }
    setIsFirstRender(true);
    filterResults(highestLevel);
    setKeyn((prevKey) => prevKey + 1);
  }, [highestLevel, HLC]);

  useEffect(() => {
    NLkey = nodeList.length;
  }, [nodeList]);

  for (const [quizId, quizData] of Object.entries(quizzes)) {
    if (groups.includes(quizData.Group)) {
      QuizzesInfo.push(quizData);
    }
  }

  function OProf() {
    setOpenProf(!openProf);
  }

  function Onote() {
    setOpenNote(!openNote);
  }

  function CPB(gs) {
    if (gs) {
      goToSlide(2);
    }
    setOpenPB(false);
  }

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        initialSlide: 1,
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        spaceBetween: "30vw",
        allowTouchMove: false,
      });
    }
  }, []);

  function goToSlide(index) {
    if (index == 2) {
      setMoveProf(true);
    } else {
      setMoveProf(false);
    }
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index, 300); // 300 is the speed in milliseconds
    }
  }

  function OPB() {
    setOpenPB(!openPB);
  }

  const handleDeletePlay = (id) => {
    setPlaysData((prev) => {
      const { [id]: _, ...newPlaysData } = prev;
      return newPlaysData;
    });

    setCoachPlays((prev) => prev.filter((playId) => playId !== id));
    if (favoritePlays.includes(id)) {
      setFavoritePlays((prev) => prev.filter((playId) => playId !== id));
    }
  };

  useEffect(() => {
    console.log("");
  }, [favoritePlays]);

  const handleAddPlay = () => {
    let newPlay = {
      title: titRef.current.value,
      coach: "Coach Smith",
      group: "Running Backs",
      id: newPlayId,
      pic: upImgList,
      caption: descRef.current.value,
      parentFolder: highestLevel.name,
    };

    setPlaysData((prev) => ({
      ...prev,
      [newPlayId]: newPlay,
    }));

    if (isTog === true) {
      setFavoritePlays((prev) => [...prev, newPlayId]);
    }
    setCoachPlays((prev) => [...prev, newPlayId]);

    setNewPlayId(newPlayId + 1);
    setUpImgList([]);

    descRef.current.value = "";
    titRef.current.value = "";
    setIsTog(false);
  };

  useEffect(() => {
    let pp = [];
    coachPlays.map((value, index) => {
      pp.push(playsData[value].parentFolder);
    });
    let tt = [];
    coachPlays.map((value, index) => {
      tt.push(playsData[value].title);
    });
    setP(pp);
    setT(tt);

    filterResults(highestLevel.name);
  }, [coachPlays]);

  const [ER, setER] = useState(false);
  const [ERlist, setERlist] = useState([]);

  function newQuiz() {
    let p = [];
    let possible = [];
    let qs = [];
    let correct = [];
    let times = [];

    let v1 = titleQuiz.current.value;
    let v2 = subQuiz.current.value;
    let v3 = DD;
    let v5 = [...questionL];

    v5.map((item, index) => {
      p.push(item.pic);
      qs.push(item.q);
      possible.push(item.answers);
      correct.push(item.correct);
      times.push(item.time);
    });

    setCurrentQuizzes((prev) => [
      ...prev,
      new QuizObj(
        v1,
        v2,
        v3,
        "Running Backs",
        v5.pic,
        "Coach Smith",
        p,
        "08/06/24",
        qs,
        possible,
        prev.length,
        correct,
        times
      ),
    ]);

    titleQuiz.current.value = "";
    subQuiz.current.value = "";
    setDD(null);
    setQuestionL([]);
    setSelectedTime("15s");
    Ref1.current.value = "";
    Ref2.current.value = "";
    Ref3.current.value = "";
    Ref4.current.value = "";
    Refq.current.value = "";
    setQpic(null);
  }

  useEffect(() => {
    console.log(currentQuizzes);
  }, [currentQuizzes]);

  const [oCal, setoCal] = useState(false);
  const onDateClick = (arg) => {
    setDD(arg.dateStr);
    setoCal(false);
  };


  const [oCurrQuiz, setOcurrQuiz] = useState(false);
  const [currQuiz, setCurrQuiz] = useState(currentQuizzes[0]);

  function currQuizClicked(n) {
    console.log(n);

    setCurrQuiz(n);

    setOcurrQuiz(true);

  }


  let pq = null;
  if ((pastQuizzes[0].pics[0]).substring(0, 2) == "./"){
    pq = require(`${pastQuizzes[0].pics[0]}`);
} else {
    pq = pastQuizzes[0].pics[0];
}
  const [title, setTitle] = useState(pastQuizzes[0].questions[0]);
  const [correct, setCorrect] = useState(pastQuizzes[0].correctAnswers[0]);
  const [ans, setAns] = useState(pastQuizzes[0].possibleAnswers[0]);
  const [QP, setQP] = useState(pq);

  

  function seeByClicked(n) {
    setTitle(pastQuizzes[0].questions[n]);
    setCorrect(pastQuizzes[0].correctAnswers[n])
    setAns(pastQuizzes[0].possibleAnswers[n]);
    let pq = null;
    if ((pastQuizzes[0].pics[n]).substring(0, 2) == "./"){
      pq = require(`${pastQuizzes[0].pics[n]}`);
  } else {
      pq = pastQuizzes[0].pics[n];
  }
    setQP(pq);

    setSeeBy(true);

  }





  const [OnewPlayer, setONewPlayer] = useState(false);
  


  return (
    <div className="appSPA2">
      {whichPage === 0 && (
        <div>
          {create && (
            <div className="overallSPAQ">
              <div onClick={() => setCreate(false)} className="back-container2">
                <svg
                  width=".9vw"
                  height="1.2vw"
                  className="triBack"
                  viewBox="0 0 99 109"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z"
                    fill="black"
                  />
                </svg>
                Back
              </div>

              <div className="innercreate1">
                <CreatequizD
                  setoCal={setoCal}
                  newQuiz={newQuiz}
                  setDD={setDD}
                  titleQuiz={titleQuiz}
                  subQuiz={subQuiz}
                />
              </div>
              <div className="innercreate1">
                <div className="qFlexD">
                  <div className="q22D">Questions</div>
                  <div
                    className="edit22D"
                    onClick={() => {
                      setERlist();
                      setER(!ER);
                    }}
                  >
                    {!ER ? "Edit" : "Remove"}
                  </div>
                </div>

                <div className="questionList">
                  {questionL.map((item, index) => {
                    return (
                      <div className="overallQBox">
                        <div className={!ER ? "QnumC" : "QnumC2"}>
                          {" "}
                          {!ER ? index + 1 : ""}
                        </div>
                        <div className="QshortText">
                          {item.q.length > 30
                            ? item.q.slice(0, 30) + "..."
                            : item.q}
                        </div>
                        <div className="timeForQ">{item.time}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="innercreate2">
                <NewQ
                  addQuestion={addQuestion}
                  Ref1={Ref1}
                  Ref2={Ref2}
                  Ref3={Ref3}
                  Ref4={Ref4}
                  Refq={Refq}
                  setSelectedTime={setSelectedTime}
                  titles={t}
                  starred={coachPlays}
                  PVV={SCP}
                  playsData={playsData}
                  pickPic={setQpic}
                />
              </div>
            </div>
          )}
          {!create && (
            <div className="overallSPAQ">
              <div className="innerSPA">
                <Quiz
                  name={firstName}
                  setAnswerList={setAnswerList}
                  answerList={answerList}
                  groups={groups}
                  quizzes={QuizzesInfo}
                  setCreate={setCreate}
                  currentQuizzes={currentQuizzes}
                  pastQuizzes={pastQuizzes}
                  openCurrQuiz={currQuizClicked}
                />
              </div>
              <div className="innerSPA2">
                <div className="resultHeadD">
                  <h className="resultHeadHead">Quiz Results</h>
                  <div className="resultQuizHead">Texas Quiz</div>
                  <div className="resultQuizSub">Protections</div>
                  <div className="resultCircleD">93%</div>
                </div>
                <div className="backgroundQP">
                  <div className="innerQuestionResult">
                    <div className="viewByQuestion">
                      View Results by Question
                    </div>
                    {isCorrect.map((question, index) => (
                      <div className="questionWrapperD">
                        <div className="questionBoxCircle2D">
                          <div className="questionCircle2D">{index + 1}</div>
                          <div className="questionBox2D">
                            <div className="QBflexD">
                              <div className="avgTXTD">Avg Score: 87%</div>
                              <div className="srBoxD" onClick={()=>seeByClicked(index)}>See Results</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="innerQuestionResult"
                    style={{ marginLeft: "1vw" }}
                  >
                    <div className="viewByQuestion">Player Stats</div>
                    <div className="teammatesWrapperD">
                      <div className="teammateFlexD">
                        <div className="teammateCircleD">
                          <img src={griffin} className="teammatePicD"></img>
                        </div>
                        <div className="teammateNameD">Griffin Speaks</div>
                        <div className="teammatePCTD">98%</div>
                      </div>
                      <div className="teammateFlexD">
                        <div className="teammateCircleD">
                          <img src={ben} className="teammatePicD"></img>
                        </div>
                        <div className="teammateNameD">Ben Hamilton</div>
                        <div className="teammatePCTD">83%</div>
                      </div>
                      <div className="teammateFlexD">
                        <div className="teammateCircleD">
                          <img src={christian} className="teammatePicD"></img>
                        </div>
                        <div className="teammateNameD">Christian Shimko</div>
                        <div className="teammatePCTD">64%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {whichPage === 1 && (
        <div className="overallSPAH">
          <div className="innerSPA">
            <Home
            currentQuizzes={currentQuizzes}
              OPB={OPB}
              Onote={Onote}
              name={firstName}
              QuizzesInfo={QuizzesInfo}
              answerList={answerList}
              setAnswerList={setAnswerList}
              groups={groups}
              pic={pic}
              oProf={setOpenProf}
              currQuizClicked={currQuizClicked}
            />
          </div>
          <div className="innerSPA2">
            <div className="RLSFlexCapD">
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    isRLS == 0
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(0)}
                  className="RLSitemD"
                >
                  <svg
                    width="34"
                    height="46"
                    viewBox="0 0 34 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="1"
                      x2="0.5"
                      y2="45"
                      stroke={isRLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="34"
                      y1="0.5"
                      y2="0.5"
                      stroke={isRLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="33"
                      y1="45.5"
                      y2="45.5"
                      stroke={isRLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="33.5"
                      x2="33.5"
                      y2="46"
                      stroke={isRLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="9"
                      y1="5.5"
                      x2="26"
                      y2="5.5"
                      stroke={isRLS == 0 ? "white" : "black"}
                    />
                    <rect
                      x="7.25"
                      y="20.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="7.25"
                      y="30.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="10.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="7.25"
                      y="10.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="20.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="30.25"
                      width="6.5"
                      height="6.5"
                      fill={isRLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={isRLS == 0 ? { color: "white" } : { color: "black" }}
                  >
                    Roster
                  </div>
                </motion.div>
              </div>
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    isRLS == 1
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(1)}
                  className="RLSitemD"
                >
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.4375 2C29.4375 0.895432 30.3329 0 31.4375 0H34.6939H39.0016C40.1062 0 41.0016 0.89543 41.0016 2V38C41.0016 39.1046 40.1062 40 39.0016 40H31.4375C30.3329 40 29.4375 39.1046 29.4375 38V2Z"
                      fill={isRLS == 1 ? "white" : "black"}
                    />
                    <path
                      d="M14.7168 10.9656C14.7168 9.86101 15.6122 8.96558 16.7168 8.96558H19.9732H24.2809C25.3855 8.96558 26.2809 9.86101 26.2809 10.9656V38.0001C26.2809 39.1046 25.3855 40.0001 24.2809 40.0001H16.7168C15.6122 40.0001 14.7168 39.1046 14.7168 38.0001V10.9656Z"
                      fill={isRLS == 1 ? "white" : "black"}
                    />
                    <path
                      d="M0 20.6205C0 19.5159 0.895431 18.6205 2 18.6205H5.25642H9.56413C10.6687 18.6205 11.5641 19.5159 11.5641 20.6205V37.9998C11.5641 39.1044 10.6687 39.9998 9.56412 39.9998H2C0.895432 39.9998 0 39.1044 0 37.9998V20.6205Z"
                      fill={isRLS == 1 ? "white" : "black"}
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={isRLS == 1 ? { color: "white" } : { color: "black" }}
                  >
                    Leaderboard
                  </div>
                </motion.div>
              </div>
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    isRLS == 2
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(2)}
                  className="RLSitemD"
                >
                  <svg
                    width="34"
                    height="46"
                    viewBox="0 0 34 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="1"
                      x2="0.5"
                      y2="45"
                      stroke={isRLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="34"
                      y1="0.5"
                      y2="0.5"
                      stroke={isRLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="33"
                      y1="45.5"
                      y2="45.5"
                      stroke={isRLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="33.5"
                      x2="33.5"
                      y2="46"
                      stroke={isRLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="9"
                      y1="5.5"
                      x2="24"
                      y2="5.5"
                      stroke={isRLS == 2 ? "white" : "black"}
                    />
                    <path
                      d="M17 14L19.2451 20.9098H26.5106L20.6327 25.1803L22.8779 32.0902L17 27.8197L11.1221 32.0902L13.3673 25.1803L7.48944 20.9098H14.7549L17 14Z"
                      fill={isRLS == 2 ? "white" : "black"}
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={isRLS == 2 ? { color: "white" } : { color: "black" }}
                  >
                    Recommended
                  </div>
                </motion.div>
              </div>
            </div>

            {isRLS === 0 && <RosterD setONewPlayer={setONewPlayer} />}
            {isRLS === 1 && <Leaderboard />}
            {isRLS === 2 && (
              <div className="RFSBottomCapD">
                {favoritePlays.map((i, index) => {
                  if ((index + 4) % 4 == 0) {
                    if (favoritePlays.length - index > 3) {
                      return (
                        <div className="playRowRFSDD">
                          <div
                            className="playDC"
                            
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index]].pic[0]
                                      }`)
                                    : playsData[favoritePlays[index]].pic[0]
                                }
                                className="playPicInRFS"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[favoritePlays[index]].title.substring(
                                0,
                                17
                              )}
                            </div>
                          </div>
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 1]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 1]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 1]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 1]].pic[0]
                                }
                                className="playPicInD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 1]
                              ].title.substring(0, 17)}
                            </div>
                          </div>

                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 2]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 2]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 2]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 2]].pic[0]
                                }
                                className="playPicInD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 2]
                              ].title.substring(0, 17)}
                            </div>
                          </div>
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 3]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 3]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 3]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 3]].pic[0]
                                }
                                className="playPicInD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 3]
                              ].title.substring(0, 17)}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (favoritePlays.length - index > 2) {
                      return (
                        <div className="playRowRFSDD">
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index]].pic[0]
                                      }`)
                                    : playsData[favoritePlays[index]].pic[0]
                                }
                                className="playPicInBD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[favoritePlays[index]].title.substring(
                                0,
                                17
                              )}
                            </div>
                          </div>
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 1]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 1]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 1]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 1]].pic[0]
                                }
                                className="playPicInBD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 1]
                              ].title.substring(0, 17)}
                            </div>
                          </div>

                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 2]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 2]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 2]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 2]].pic[0]
                                }
                                className="playPicInBD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 2]
                              ].title.substring(0, 17)}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (favoritePlays.length - index > 1) {
                      return (
                        <div className="playRowRFSDD">
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index]].pic[0]
                                      }`)
                                    : playsData[favoritePlays[index]].pic[0]
                                }
                                className="playPicInBD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[favoritePlays[index]].title.substring(
                                0,
                                17
                              )}
                            </div>
                          </div>
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index + 1]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index + 1]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index + 1]]
                                          .pic[0]
                                      }`)
                                    : playsData[favoritePlays[index + 1]].pic[0]
                                }
                                className="playPicInD"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[
                                favoritePlays[index + 1]
                              ].title.substring(0, 17)}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="playRowRFSDD">
                          <div
                            className="playDC"
                            onClick={() => SCP([favoritePlays[index]])}
                          >
                            <div className="playPicBD">
                              <img
                                src={
                                  playsData[favoritePlays[index]].id < 17
                                    ? require(`${
                                        playsData[favoritePlays[index]].pic[0]
                                      }`)
                                    : playsData[favoritePlays[index]].pic[0]
                                }
                                className="playPicInRFS"
                              />
                            </div>
                            <div className="playTitleBD">
                              {playsData[coachPlays[index]].title.substring(
                                0,
                                17
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            )}
          </div>
        </div>
      )}

      {whichPage === 2 && (
        <div className="overallSPAP">
          <h className="pHead">Playbook</h>
          <div className="pbHeadFlexFlexD" onClick={() => setOpenFilter(true)}>
            <div className="pSub">Running Backs</div>
            <svg
              className="triD"
              width="1vw"
              height="1.5vw"
              viewBox="0 0 181 99"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M90.5 99L0.866364 0.75L180.134 0.75L90.5 99Z"
                fill="#93A3BB"
              />
            </svg>
          </div>

          <div className="innerSPAP">
            <div className="playbookOver">
              <div className="playFolderHeadFlex">
                <div className="addSearch">
                  <motion.svg
                    width="2.5vw"
                    height="2.5vw"
                    viewBox="0 0 45 45"
                    fill="none"
                    className="r"
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setOpenNF(true)}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="45" height="45" rx="15" fill="white" />
                    <rect
                      x="20.9453"
                      y="12.1436"
                      width="3.11642"
                      height="20.7143"
                      rx="1.55821"
                      fill="black"
                    />
                    <rect
                      width="19.4776"
                      height="3.31429"
                      rx="1.65714"
                      transform="matrix(1 0 0 -1 12.7637 24.1589)"
                      fill="black"
                    />
                  </motion.svg>

                  <motion.svg
                    width="2.5vw"
                    height="2.5vw"
                    viewBox="0 0 250 250"
                    fill="none"
                    className="r"
                    onClick={() => setSearch(true)}
                    whileTap={{ scale: 0.9 }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="250" height="250" rx="78" fill="white" />
                    <path
                      d="M164 107.5C164 138.176 139.351 163 109 163C78.6486 163 54 138.176 54 107.5C54 76.8239 78.6486 52 109 52C139.351 52 164 76.8239 164 107.5Z"
                      stroke="black"
                      stroke-width="6"
                    />
                    <path
                      d="M198.749 201.97L144.503 146.499"
                      stroke="black"
                      stroke-width="12"
                    />
                  </motion.svg>
                </div>
                <h className="groupHeadd">Running Backs</h>

                <div className="pbSplit">
                  <div className="foldersD">
                    <svg
                      width="1.5vw"
                      height="1.5vw"
                      onClick={back}
                      viewBox="0 0 11 19"
                      fill="none"
                    >
                      <path
                        d="M9.5 1L1 9.5L9.5 18"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="folderCapD" key={keyn}>
                      {folderComponent}
                    </div>
                  </div>
                  <div className="playsD">
                    <div className="pbBottomCapD">
                      {resultList.map((item, index) => {
                        if ((index + 3) % 3 == 0) {
                          if (resultList.length - index > 2) {
                            return (
                              <div className="playRowRFSDD">
                                <div
                                  className="playD"
                                  onClick={() => SCP([resultList[index]])}
                                  key={index - resultList[index].id}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index].id < 17
                                          ? require(`${resultList[index].pic[0]}`)
                                          : resultList[index].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index].title}
                                  </div>
                                </div>
                                <div
                                  className="playD"
                                  key={index - resultList[index].id}
                                  onClick={() => SCP([resultList[index + 1]])}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index + 1].id < 17
                                          ? require(`${
                                              resultList[index + 1].pic[0]
                                            }`)
                                          : resultList[index + 1].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index + 1].title}
                                  </div>
                                </div>
                                <div
                                  className="playD"
                                  key={index - resultList[index].id}
                                  onClick={() => SCP([resultList[index + 2]])}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index + 2].id < 17
                                          ? require(`${
                                              resultList[index + 2].pic[0]
                                            }`)
                                          : resultList[index + 2].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index + 2].title}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (resultList.length - index > 1) {
                            return (
                              <div className="playRowRFSDD">
                                <div
                                  className="playD"
                                  onClick={() => SCP([resultList[index]])}
                                  key={index - resultList[index].id}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index].id < 17
                                          ? require(`${resultList[index].pic[0]}`)
                                          : resultList[index].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index].title}
                                  </div>
                                </div>
                                <div
                                  className="playD"
                                  key={index - resultList[index].id}
                                  onClick={() => SCP([resultList[index + 1]])}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index + 1].id < 17
                                          ? require(`${
                                              resultList[index + 1].pic[0]
                                            }`)
                                          : resultList[index + 1].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index + 1].title}
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="playRowRFSDD"
                                key={index - resultList[index].id}
                              >
                                <div
                                  className="playD"
                                  key={index - resultList[index].id}
                                  onClick={() => SCP([resultList[index]])}
                                >
                                  <div className="playPicBD">
                                    <img
                                      src={
                                        resultList[index].id < 17
                                          ? require(`${resultList[index].pic[0]}`)
                                          : resultList[index].pic[0]
                                      }
                                      className="playPicInRFSD"
                                    />
                                  </div>
                                  <div className="playTitlee">
                                    {resultList[index].title}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setOpenDF(true);
                  }}
                  className="DeleteFolderD"
                >
                  Delete Folder
                </div>
                <div className="viewAllFilesD" onClick={() => SCP(resultList)}>
                  View All Files
                </div>
              </div>
            </div>
            <div className="newFile">
              <div className="newFHead">New File</div>
              <div className="newFSub">Auburn University</div>
              <input
                className="inputTD"
                ref={titRef}
                id="in2"
                placeholder="Title"
              ></input>
              <input
                className="inputTD"
                ref={descRef}
                placeholder="Description"
              ></input>
              <div className="inputUD2" onClick={() => setUP(true)}>
                <input type="file" style={{ display: "none" }} />
                <div
                  style={
                    upImgList.length > 0
                      ? { color: "black" }
                      : { color: "black" }
                  }
                >
                  Upload Image
                </div>
                <div className="arrowCircle">
                  {upImgList.length > 0 && (
                    <div className="outerCirc">{upImgList.length}</div>
                  )}
                  <svg
                    width=".9vw"
                    height=".9vw"
                    className="arrowUpImg"
                    viewBox="0 0 8 9"
                    fill="none"
                  >
                    <path
                      d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                      fill={upImgList.length > 0 ? "black" : "black"}
                    />
                  </svg>
                </div>
              </div>
              <div className="overallToggleFlex">
                <div className="starToggleTxt">Add to Recommended?</div>
                <div
                  onClick={() => setIsTog(!isTog)}
                  className={!isTog ? "outOval" : "outOval2"}
                >
                  <div className={!isTog ? "inOval" : "inOval2"}></div>
                </div>
              </div>
              <div className="personnelTextD">Personnel:</div>

              <motion.div
                style={{ border: "1.5px solid #5259FB" }}
                className="group222"
              >
                <div style={{ color: "#5259FB" }} className="groupText222">
                  Running Backs
                </div>
              </motion.div>

              <motion.div
                whileTap={{ scale: 0.9 }}
                onClick={handleAddPlay}
                className="ATP"
              >
                Add To Playbook
              </motion.div>
            </div>
          </div>
        </div>
      )}

      <Foot
        i={whichPage}
        setPage={setPage}
        goToSlide={goToSlide}
        setRight={setRight}
      />

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openProf && (
          <Profile
            close={OProf}
            pic={pic}
            num={num}
            name={playerInfo.name}
            group={groups[0]}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openFilter && (
          <Filter
            handleClose={() => setOpenFilter(false)}
            groupTF={groupTF}
            setGroup={setGroup}
            setGroupTF={setGroupTF}
            group={group}
            groupList={["Running Backs"]}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openPV && (
          <PlayViewD
            close={() => setOpenPV(false)}
            play={currID}
            setFavoritePlays={setFavoritePlays}
            favoritePlays={favoritePlays}
            handleDeletePlay={handleDeletePlay}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openNF && (
          <NewFolder
            close={() => setOpenNF(false)}
            fName={highestLevel.name}
            addFolder={addFolder}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openDF && (
          <DeleteFolderD close={() => setOpenDF(false)} DF={DeleteFolder} />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openSearch && (
          <SearchMod
            close={() => setSearch(false)}
            titles={t}
            starred={coachPlays}
            PVV={SCP}
            playsData={playsData}
            ifPic={false}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openUP && (
          <UploadFiles
            close={() => setUP(false)}
            upImgList={upImgList}
            setUpImgList={setUpImgList}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openPB && (
          <Playbook
            CPB={CPB}
            p={p}
            group={group}
            groupTF={groupTF}
            setGroupTF={setGroupTF}
            setGroup={setGroup}
            setCurrentPlays={setCurrentPlays}
            starred={coachPlays}
            groupList={groups}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oCal && (
          <div className="blackBack">
            <div className="calCapD">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={false}
                dateClick={onDateClick}
              />
            </div>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oCurrQuiz && (
          <CurrQuizView currentQuizzes={currentQuizzes}  setCurrentQuizzes={setCurrentQuizzes} close={()=> setOcurrQuiz(false)} openCurrQuiz={currQuizClicked} quiz={currQuiz}/>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {seeBy && (
          <SeeByQ currentQuizzes={currentQuizzes}  titlen={title} correctn={correct} ansn={ans} pqn={QP} setCurrentQuizzes={setCurrentQuizzes} close={()=> setSeeBy(false)} openCurrQuiz={currQuizClicked} quiz={currQuiz}/>
        )}
      </AnimatePresence>


      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {OnewPlayer && (
          <NewPlayer close={()=>setONewPlayer(false)}/>
        )}
      </AnimatePresence>

      
    </div>
  );
};

export default SPAC;
