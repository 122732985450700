import "./DeleteFolder.css";
import React, { useRef , useState} from 'react';
import { motion } from "framer-motion";

const DeleteFolderD = ({
    close,
    DF
}) => {


    const inputRef = useRef(null);
    

    const check = () => {
        if(inputRef.current.value === "Delete"){
            close();
            DF();
        }
      };





  return (
    <div>
      <motion.div className="fullProfDD" onClick={close}></motion.div>

      <div className="DFbox">

        <div className="DFhead">Delete Folder</div>
        <div className="DFsub">This action will permanently delete all subfolders and files within this folder. If you would like to proceed, type "Delete" in this text field.</div>
        <input className="DFin" ref={inputRef} style={{color:"black"}} type="text"></input>
        <motion.div whileTap={{scale: 0.9}}   onClick={check} className="DFdel">Delete</motion.div>
      </div>
      
      

    </div>
  );
};

export default DeleteFolderD;
