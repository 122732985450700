import "./SeeByQ.css";
import plays from "./JSON/Plays.json";
import { motion, AnimatePresence } from "framer-motion";
import Question from "./Quiz/QuestionC";
import { useState, useEffect, useRef } from "react";
import Swiper from "swiper";
import "/node_modules/swiper/swiper.min.css";

const NewPlayer = ({ close}) => {




  return (
    <div>
      <motion.div className="fullProfDDD" onClick={close}></motion.div>

      

      <div className="currQuizOver">
        

        </div>
        
        
    </div>
  );
};

export default NewPlayer;
