import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef } from "react";

const NewPlayer = ({ CS , poc}) => {

  const [pocc, setPocc] = useState(poc === "Coach" ? true : false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    fileInputRef.current.click();
};

  
  const dropIn = {
    hidden: {
      x: "100vw",
      opacity: 1,
    },
    visible: {
      x: "0vw",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 30,
        stiffness: 300,
      },
    },
    exit: {
      x: "100vw",
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="createFull"
    >
      <div onClick={()=>CS(false)} className="back-container">
        <svg
          width="2vw"
          height="5vw"
          className="triBack"
          viewBox="0 0 99 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z" fill="black" />
        </svg>

        <div className="backTxt">Back</div>
      </div>

      <div className="create-Cap">
      <div className="createHead">New {poc}</div>
      <div className="createSub">Auburn University</div>

      {
        !pocc &&

        <div>
          <input className="inputT" defaultValue="Name"></input>
      <input className="inputT" defaultValue="Jersey Number"></input>
      <input className="inputT" defaultValue="Player Email"></input>
      <input className="inputT" defaultValue="Year"></input>
      <input className="inputT" defaultValue="Position"></input>
      <div className="inputU"  onClick={handleFileChange}>
      <input type="file" className="fileUp" ref={fileInputRef} onChange={handleFileChange} />
        <div>Upload Image</div>
        <svg width="2.7vw" height="2.7vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="black"
              />
            </svg>
        </div>
        </div>
      }

{
        pocc &&

        <div>
          <input className="inputT" defaultValue="Name"></input>
      <input className="inputT" defaultValue="Coach Email"></input>
      <input className="inputT" defaultValue="Title"></input>
      <div className="inputU"  onClick={handleFileChange}>
      <input type="file" className="fileUp" ref={fileInputRef} onChange={handleFileChange} />
        <div>Upload Image</div>
        <svg width="2.7vw" height="2.7vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="black"
              />
            </svg>
        </div>
        </div>
      }
      

      <div className="personnelText">Personnel:</div>

      <motion.div style={{border : "1.5px solid #5259FB"}} className="group22"><div style={{color : "#5259FB"} }  className="groupText22">Running Backs</div></motion.div>


      <div className="PCAP">
        <motion.div className="PBUT" whileTap={{ scale: 0.9 }} onClick={()=>CS(false)}>
            Add {poc}
        </motion.div>
      </div>
      
      </div>

    
   
    </motion.div>
  );
};

export default NewPlayer;
