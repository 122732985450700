import "./NewFolder.css";
import React, { useRef , useState} from 'react';
import { motion } from "framer-motion";

const NewFolder = ({
  close,
  fName,
  addFolder
}) => {


    const inputRef = useRef(null);
    const [GB, setGB] = useState(false)
    

    const addF = () => {
        close();
        console.log(inputRef.current.value)
        addFolder(inputRef.current.value);
      };

      const clear = () => {
        setGB(true);
        if(inputRef.current.value === "Folder Name"){
            inputRef.current.value = "";
        }
        
      };



  return (
    <div>
      <motion.div className="fullProfDD" onClick={close}></motion.div>

      <div className="NFbox">

        <div className="NFhead">New Folder</div>
        <input className="NFin" ref={inputRef} onClick={clear} style={GB ? {color:"black"}: {color:"#bcbcbc"}} defaultValue={"Folder Name"} type="text"></input>
        <motion.div whileTap={{scale: 0.9}}   onClick={addF} className="NFadd">Add to {fName}</motion.div>
      </div>
      
      

    </div>
  );
};

export default NewFolder;
