import "./foot.css";
import { useState } from "react";
import { motion } from "framer-motion";

const Foot = ({ i, setPage, goToSlide , setRight}) => {
  const toP = (event) => {
    setRight("-24vw");
    setPage(2);
    goToSlide(2);
  };

  const toQ = (event) => {
    setRight("-36vw");
    setPage(0);
    goToSlide(0);
  };

  const toH = (event) => {
    setRight("-30vw");
    setPage(1);
    goToSlide(1);
  };

  return (
    <div className="foot">
      <div className="iconFlex">
        <svg
          fill={i == 0 ? "#0072e2" : "#000000"}
          width="15%"
          height="16%"
          id="Layer_1"
          onClick={toQ}
          data-name="Layer 1"
          viewBox="0 0 300 300"
        >
          <path d="m194.71,126.66c0,3.77-3.06,6.84-6.83,6.84h-81.69c-3.76,0-6.83-3.07-6.83-6.84s3.07-6.83,6.83-6.83h81.69c3.77,0,6.83,3.06,6.83,6.83Z" />
          <path d="m270.62,120.57l-10.28-6.77c-2.05-1.33-4.43-2.03-6.86-2.03h-.62c-4.28,0-8.23,2.13-10.58,5.71l-60.12,92.1c-.45.74-.86,1.52-1.25,2.38l-9.56,25.2c-2.14,5.58-.16,11.77,4.81,15.08,2.1,1.41,4.54,2.15,7.05,2.15h.13c3.16,0,6.18-1.17,8.53-3.3l19.83-18.55c.77-.72,1.46-1.53,2.03-2.41l60.08-92.05c3.87-5.68,2.45-13.53-3.19-17.51Zm-84.92,117.51l8.02-21.11,59.49-91.14,8.71,5.67-59.6,91.13-16.62,15.45Z" />
          <path d="m159.71,220.02c0,3.76-3.07,6.83-6.84,6.83h-46.68c-3.76,0-6.83-3.07-6.83-6.83s3.07-6.84,6.83-6.84h46.68c3.77,0,6.84,3.07,6.84,6.84Z" />
          <path d="m178.29,173.34c0,3.77-2.53,6.83-5.65,6.83h-67.62c-3.11,0-5.65-3.06-5.65-6.83s2.54-6.84,5.65-6.84h67.62c3.12,0,5.65,3.07,5.65,6.84Z" />
          <path d="m194.71,79.98c0,3.77-3.06,6.84-6.83,6.84h-81.69c-3.76,0-6.83-3.07-6.83-6.84s3.07-6.83,6.83-6.83h81.69c3.77,0,6.83,3.07,6.83,6.83Z" />
          <path d="m222.39,128.27V56.64c0-9.1-7.4-16.5-16.5-16.5H54.19c-9.1,0-16.51,7.4-16.51,16.5v186.72c.18,8.89,7.58,16.15,16.5,16.15h151.35c1.05.12,2.12.12,3.17.01,1.26-.22,2.53-.65,3.71-1.29,5.83-2.69,9.54-8.52,9.52-14.87v-5.44l13.67-20.37v26.15c.03,9.47-4.48,18.5-12.08,24.15-1.94,1.5-4.11,2.73-6.42,3.63-2.77,1.1-5.69,1.79-8.68,2.05H54.19c-16.64,0-30.18-13.54-30.18-30.17V56.64c0-16.63,13.54-30.17,30.18-30.17h151.7c16.64,0,30.17,13.54,30.17,30.17v50.71l-13.67,20.92Z" />
          <path d="m72.91,139.9h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m72.91,186.57h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m72.91,233.25h-12.13c-3.95,0-7.17-3.22-7.17-7.17v-12.13c0-3.95,3.22-7.17,7.17-7.17h12.13c3.95,0,7.17,3.22,7.17,7.17v12.13c0,3.95-3.22,7.17-7.17,7.17Zm-9.71-9.59h7.29v-7.29h-7.29v7.29Z" />
          <path d="m67.45,94.87c-1.52,0-2.96-.73-3.87-1.96l-12.31-16.84c-1.56-2.14-1.1-5.14,1.04-6.7,2.14-1.56,5.14-1.1,6.7,1.04l7.99,10.93,16.06-27.52c1.33-2.29,4.27-3.06,6.56-1.73,2.29,1.33,3.06,4.27,1.72,6.56l-19.75,33.84c-.81,1.4-2.28,2.29-3.89,2.37-.08,0-.17,0-.25,0Z" />
        </svg>
        <svg
          fill={i == 1 ? "#0072e2" : "#000000"}
          width="13.5%"
          height="12%"
          onClick={toH}
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 500 470"

        >
          <g>
            <g>
              <g>
                <path
                  d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
				v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
				c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
				c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"
                />
                <path
                  d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
				c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
				c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"
                />
              </g>
            </g>
          </g>
        </svg>
        <svg
          fill={i == 2 ? "#0072e2" : "#000000"}
          width="14%"
          height="30%"
          onClick={toP}
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 300 300"

        >
          <path d="m160.99,223.13c1.69,1.69,2.59,3.9,2.47,6.08-.11,1.99-1.03,3.79-2.59,5.09-1.36,1.29-3.32,2.03-5.4,2.03s-4.15-.78-5.52-2.15l-10.85-10.85-11.05,11.06-.25.16c-1.35.9-2.86,1.78-5.07,1.78s-4.15-.78-5.52-2.15c-1.69-1.69-2.58-3.9-2.46-6.08.11-1.98,1.02-3.77,2.56-5.07l10.75-10.74-10.85-10.85c-1.69-1.68-2.58-3.9-2.46-6.08.11-1.98,1.02-3.78,2.58-5.08,1.67-1.61,3.83-2.47,5.96-2.35,1.98.11,3.77,1.02,5.07,2.57l10.74,10.74,10.85-10.84c1.68-1.69,3.9-2.59,6.08-2.47,1.98.11,3.78,1.03,5.08,2.58,1.61,1.67,2.46,3.84,2.35,5.97-.11,1.97-1.02,3.77-2.57,5.06l-10.74,10.75,10.84,10.84Z" />
          <path d="m91.69,171.69c1.69,1.68,2.58,3.9,2.46,6.08-.11,1.98-1.02,3.78-2.58,5.08-1.37,1.3-3.32,2.04-5.4,2.04s-4.15-.79-5.52-2.16l-10.85-10.85-10.85,10.85c-1.37,1.37-3.38,2.16-5.52,2.16s-4.15-.79-5.52-2.16c-1.69-1.68-2.58-3.9-2.46-6.08.11-1.97,1.01-3.77,2.56-5.07l10.74-10.74-10.84-10.84c-1.69-1.69-2.58-3.91-2.46-6.08.11-1.99,1.02-3.78,2.57-5.08,1.67-1.62,3.85-2.47,5.97-2.35,1.97.11,3.77,1.02,5.07,2.56l10.74,10.74,10.85-10.84c1.68-1.68,3.89-2.58,6.08-2.46,1.98.11,3.78,1.02,5.08,2.58,1.61,1.67,2.46,3.83,2.34,5.96-.11,1.98-1.02,3.77-2.56,5.07l-10.74,10.74,10.84,10.85Z" />
          <path d="m221.97,93.31c1.68,1.68,2.58,3.9,2.46,6.08-.11,1.98-1.03,3.78-2.59,5.08-1.36,1.3-3.32,2.04-5.4,2.04s-4.15-.79-5.52-2.16l-10.85-10.84-10.84,10.84c-1.37,1.37-3.38,2.16-5.53,2.16s-4.15-.79-5.52-2.16c-1.68-1.68-2.58-3.9-2.46-6.08.11-1.97,1.02-3.77,2.57-5.06l10.74-10.74-10.85-10.85c-1.68-1.69-2.58-3.9-2.46-6.08.11-1.99,1.02-3.78,2.58-5.08,1.67-1.61,3.84-2.47,5.96-2.35,1.98.11,3.77,1.02,5.07,2.56l10.75,10.75,10.84-10.85c1.69-1.69,3.92-2.59,6.08-2.46,1.98.11,3.78,1.02,5.08,2.58,1.61,1.67,2.47,3.83,2.35,5.96-.11,1.98-1.02,3.77-2.57,5.07l-10.74,10.74,10.85,10.85Z" />
          <path d="m226.05,220.98c-15.72,0-28.51,12.79-28.51,28.51s12.79,28.51,28.51,28.51,28.51-12.79,28.51-28.51-12.79-28.51-28.51-28.51Zm0,41.24c-7.02,0-12.73-5.71-12.73-12.73s5.71-12.73,12.73-12.73,12.73,5.83,12.73,12.73-5.71,12.73-12.73,12.73Z" />
          <path d="m235,181.88v13.13c0,4.03-3.44,7.31-7.68,7.31s-8.1-3.53-8.1-7.7v-12.45c-2-30.94-27.4-36.96-56.8-43.92l-1.26-.3c-29.42-6.59-66.09-14.8-66.09-57.41v-29.47l-5.33,6.05c-1.35,1.66-3.27,2.62-5.41,2.71-1.97.09-3.94-.58-5.56-1.87-3.32-2.66-3.71-7.5-.9-11.02l.1-.12,19.44-21.97c1.35-1.89,3.43-2.85,6.19-2.85s4.85.96,6.2,2.86l19.1,22.08c2.82,3.52,2.43,8.36-.89,11.02l-.21.15c-1.7,1.13-3.54,1.78-5.07,1.78-2.23,0-4.23-.87-5.94-2.58l-.13-.14-5.38-6.11v30.26c0,30.68,23.73,35.92,53.77,42.55l.25.06c15.72,3.62,31.97,7.37,45.1,15.52,15.35,9.53,23.39,23.41,24.6,42.43Z" />
        </svg>
      </div>
    </div>
  );
};

export default Foot;
