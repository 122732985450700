import {motion} from "framer-motion";
import "./pFilter.css"
import Backdrop from "./drop";

const Filter = ({handleClose, groupList, group, groupTF, setGroup}) => {
      
    const gClick = (e) => {
        if(e.target.className == "groupText" || e.target.className == "groupText2" ){
            if(groupTF[e.target.parentElement.id] == true){
            
                if (groupList.length > 1)
                {
                    e.target.style.color = "#000000";
                    e.target.parentElement.style.border = "1.5px solid #707070";
                    let groupListBCopy = groupTF;
                    groupListBCopy[e.target.parentElement.id] = false;
                }
            
            }else{
            
                e.target.style.color = "#5259FB";
                e.target.parentElement.style.border = "1.5px solid #5259FB";
                let groupListBCopy = groupTF;
                groupListBCopy[e.target.parentElement.id] = true;
            
            }
        }
        
    }

    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity:0
        },
        visible: {
            y: "0",
            opacity:1,
            transition: {
                duration:0.05,
                type: "spring",
                damping: 22,
                stiffness: 400
            }
        },
        exit: {
            y: "100vh",
            opacity:0
        }
    }


    return(
        <Backdrop onClick={handleClose}>
            <motion.div
            className="pFilterD"
            >
                <div className="pfHeadD">Personnel Filter</div>
                <div className="pfCapD">
                    <div className="pfSubD">Select Personnel:</div>
                        <div className="groupsD">
                            {groupList.map((item, index) => (
                                <motion.div  key={index} id={index} onClick={gClick} style={groupTF[index] ? {border : "1.5px solid #5259FB"} : {border : "1.5px solid #707070"}} className="groupD"><div style={groupTF[index] ? {color : "#5259FB"} : {color : "#000000"}}  className="groupTextD">{item}</div></motion.div>
                            ))}
                        </div>
                </div>
                <div className="flexButtonD">
                    <motion.button className="goButD" whileTap={{scale:.9, color:"#8186FF"}}onClick={handleClose}>Go</motion.button>
                </div>
            </motion.div> 
        </Backdrop>
    )
}

export default Filter;