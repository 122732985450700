import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./RosterD.css";
import Swiper from "swiper";
import "/node_modules/swiper/swiper.min.css";
import ProfListC from "./profListCD";
import NewPlayer from "./newPlayer";
import GroupList from "./groupListD";
import Group from "./Group";
import Modal from "../Quiz/ModalD";


const RosterD = ({ setONewPlayer }) => {

  const [del, setDel] = useState(false);


  const [oNew, setoNew] = useState(false);
  const [poc, setPoc] = useState(false);
  const [G, openG] = useState(false);
  const [whichSlide, setSlide] = useState(0);
  const [whichG, setWhichG] = useState("");


  function pocChoose(p, n) {
    setPoc(p);
    setoNew(n);
  }
  const switchUnder = (e) => {
    const headList = [document.getElementsByClassName("navItem11D"),document.getElementsByClassName("navItem22D"),document.getElementsByClassName("navItem33D")]
    headList.map((e, index) => {
      (headList[index][0]).style.borderBottom = "none";
    })
    e.target.style.borderBottom = ".2vw solid black";
    setSlide(e.target.id)
  }

  return (
    <motion.div
      className="fullResultD"
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div className="Result-CapD">
        

          <div className="navHead2D">
            <div
              className="navItem11D"
              id="0"
              onClick={switchUnder}
              style={{ borderBottom: ".2vw solid black" }}
            >
              Players
            </div>

            <div className="navItem22D" onClick={switchUnder} id="1">
              Coaches
            </div>

            <div className="navItem33D" onClick={switchUnder} id="2">
              Groups
            </div>
          </div>
          
          
          
          {whichSlide == 0 &&
           <div className="qFlex22D" onClick={setONewPlayer}>
           <div>
           <div className="newPlayerD" >New Player</div>
           <div className="ttaD">Tap to Add</div>
           </div>
           
           <svg width="1.5vw" height="1.5vw" viewBox="0 0 8 9" fill="none">
                 <path
                   d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                   fill="black"
                 />
               </svg>
         </div>
            }
        
        
        
        {whichSlide == 1 && 
          <div className="qFlex22D" onClick={setONewPlayer}>
        <div>
        <div className="newPlayerD" >New Coach</div>
        <div className="ttaD">Tap to Add</div>
        </div>
        
        <svg width="1.5vw" height="1.5vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="black"
              />
            </svg>
          </div>
        }

          {whichSlide == 0 && <ProfListC setDel={setDel} pic="coach" order={[2,3,1]} scores={["3:28", "8:19", "5:15"]} name="Christian Shimko" tf={true} tfd={true}/>}
        {whichSlide == 1 && <ProfListC setDel={setDel} pic="griff" order={[3,2,1]} scores={["77%","89%","94%"]} name="Griffin Speaks" tf={true} tfd={true}/>}
        {whichSlide == 2 && <GroupList openG={openG} setONewPlayer={setONewPlayer} setWhichG={setWhichG} />}
        
        

        <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oNew && (
          <NewPlayer CS={setoNew} poc={poc}/>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {G && (
          <Group CS={openG} head={whichG}/>
        )}
      </AnimatePresence>
      
      </motion.div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && <Modal  close={() => setDel(false)} del={() => setDel(false)} />}
      </AnimatePresence>
    </motion.div>
  );
};

export default RosterD;
