import "./SeeByQ.css";
import plays from "./JSON/Plays.json";
import { motion, AnimatePresence } from "framer-motion";
import Question from "./Quiz/QuestionC";
import { useState, useEffect, useRef } from "react";
import Swiper from "swiper";
import "/node_modules/swiper/swiper.min.css";

const SeeByQ = ({ close, quiz, setCurrentQuizzes, currentQuizzes, titlen, correctn, ansn, pqn }) => {

  let pq = null;
  if ((quiz.pics[0]).substring(0, 2) == "./"){
    pq = require(`${quiz.pics[0]}`);
} else {
    pq = quiz.pics[0];
}
  const [t, setT] = useState(quiz.questions[0]);
  const [correct, setCorrect] = useState(quiz.correctAnswers[0]);
  const [ans, setAns] = useState(quiz.possibleAnswers[0]);
  const [QP, setQP] = useState(pq);
  const [qNum, setQNUM] = useState(1);
  const [del, setDel] = useState(false);





  function goNext (){

    if (qNum<quiz.questions.length){
        setQNUM(qNum+1);
    }

  }
  function goBack () {

    if (qNum>1){
        setQNUM(qNum-1);
    }

  }

  useEffect(()=> {
    setT(quiz.questions[qNum-1]);
    setCorrect(quiz.correctAnswers[qNum-1]);
    setAns(quiz.possibleAnswers[qNum-1]);
    let p = null;
    if ((quiz.pics[qNum-1]).substring(0, 2) == "./"){
        p = require(`${quiz.pics[qNum-1]}`);
    } else {
        p = quiz.pics[qNum-1];
    }
    setQP(p);

  }, [qNum])
  


function close2() {

    const newItems = currentQuizzes.filter((x, i) => x.id !== quiz.id);
    setCurrentQuizzes(newItems);

    close();

}


  return (
    <div>
      <motion.div className="fullProfDDD"></motion.div>

      <div className="topBar">
        <div onClick={close} className="back-containerPV">
          <svg
            width="1.7vh"
            height="1.7vh"
            className="triBackD"
            viewBox="0 0 99 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z"
              fill="white"
            />
          </svg>
          <div className="backTxtProfD">Back</div>
        </div>
        <div className="innerFlexTopBarC">
          <div className="topBarTitle">Running Backs Basics</div>
        </div>
      </div>

      <div className="currQuizOver">
        <div className="qOver">
          <div className="currQuizQuestion">{titlen}</div>
          <div className="playPicCapDQuiz">
            <img src={pqn} className="playPicDQuiz"></img>
          </div>
          <div className="answersCD">
            <div className={correctn == 0 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[0]}</div>
            </div>
            <div className={correctn == 1 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">
                {ansn[1]}
              </div>
            </div>
          </div>

          <div className="answersCD">
            <div className={correctn == 2 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[2]}</div>
            </div>
            <div className={correctn == 3 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[3]}</div>
            </div>
          </div>
        </div>

        <div className="playersQuestion">
        <div className="PlayersHeadtxtD">Player Stats:</div>
        <div className="oFlexPlayersD">
          <div className="iiFlexPlayerD">
            <svg
                width="1.7vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              <div className="playerResTxtD">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayerDC">
            <svg
                width="2.4vw"
                height=""
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxtDC">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayerD">
            <svg
                width="1.7vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              <div className="playerResTxtD">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayerDC">
            <svg
                width="2.4vw"
                height=""
                viewBox="0 0 27 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxtDC">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayerD">
            <svg
                width="1.7vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              <div className="playerResTxtD">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayerDC">
            <svg
                width="2.4vw"
                height=""
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxtDC">Griffin Speaks</div>
            </div>
          
        </div>

        </div>
        
        

        
      </div>
      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && (
          <div onClick={()=> setDel(false)} className="blackBack">
            <div className="delBox">
              <h className="delBoxHead">Delete Quiz</h>
              <div>Are you sure you want to delete this Quiz? This action can not be undone.</div>
              <motion.div whileTap={{scale: 0.9}} onClick={close2}   className="DPdel">Delete</motion.div>
            </div>

          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SeeByQ;
