import "./Leaderboard.css";
import ProfList from "./profListD";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";



const Leaderboard = ({CS}) => {


  const [whichSlide, setSlide] = useState(0)

  const switchUnder = (e) => {
    const headList = [document.getElementsByClassName("navItem1D"),document.getElementsByClassName("navItem2D"),document.getElementsByClassName("navItem3D")]
    headList.map((e, index) => {
      (headList[index][0]).style.borderBottom = "none";
    })
    e.target.style.borderBottom = ".2vw solid black";
    setSlide(e.target.id)

  }

  

  return (
    <motion.div
    className="App1D">


        <div className="bodD">
        <div className="navHeadD">
          <div className="navItem1D" id="0" onClick={switchUnder}style={{borderBottom: ".2vw solid black"}}>Time</div>

          <div className="navItem2D" id="1" onClick={switchUnder}>Quiz Avg</div>

          <div className="navItem3D" id="2" onClick={switchUnder}>GamePrep Score</div>
        </div>

        {whichSlide == 0 && <ProfList  pic="prof" order={[2,3,1]} scores={["3:28", "8:19", "5:15"]} name="Christian Shimko"/>}
        {whichSlide == 1 && <ProfList  pic="griff" order={[3,2,1]} scores={["77%","89%","94%"]} name="Griffin Speaks"/>}
        {whichSlide == 2 && <ProfList  pic="ben" order={[2,3,1]} scores={["78", "93", "64"]} name="Ben Hamilton"/>}

        </div>
    </motion.div>
  );
}

export default Leaderboard;
