import { useState, useEffect } from "react";

const QinL = ({ ER, item, index, setQLR }) => {
    const [isR, setIsR] = useState(false);

    function ss () {
        if (isR){
            setIsR(false);
        }
        else {
            setIsR(true);
        }
        
        //setQLR(index);

    }



  return (
    <div className="overallQBox">
    <div onClick={ER?ss():null} style={ER && isR ? {backgroundColor: "red"}: {backgroundColor: "white"}} className={!ER ? "QnumC" : "QnumC2"}>{"hi"}</div>
    <div className="QshortText">
        {item.q.length > 30 ? item.q.slice(0, 30) + "..." : item.q}
    </div>
    <div className="timeForQ">{item.time}</div>
    </div>
      
  );
};

export default QinL;
