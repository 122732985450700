import React from 'react';
import { useState, useEffect } from "react";
import SI from './Mobile/Sign In/SI.js';
import SID from './Desktop/Sign In/SID.js';




function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  console.log(isMobile);

  return (

    <div>
      {isMobile && <SI/>}
      {!isMobile && <SID/>}
    </div>
    
  )

}


export default App;